<template>
  <introduction-template
    :service-name="$route.name"
    :title="$t(`button['audio and speech analysis']`)"
    :description="$t(`services.asa.description`)"
    :particle-options="particleOptions"
    :features="servicesCards"
    service-abbrev="ASA"
  >
    <template slot="banner">
      <banner class="animate-svg-blocks" />
    </template>
  </introduction-template>
</template>
<script>
import iconASR from '@/assets/images/icons/services/introduction/asa/asr.svg';
import iconTTS from '@/assets/images/icons/services/introduction/asa/tts.svg';
import iconSNSD from '@/assets/images/icons/services/introduction/asa/snsd.svg';
import iconSLD from '@/assets/images/icons/services/introduction/asa/sld.svg';
import Banner from '../../components/BannerBlocks/ASA.vue';
import IntroductionTemplate from '../../components/IntroductionTemplate/Index.vue';

export default {
  components: { IntroductionTemplate, Banner },
  data() {
    return {
      particleOptions: { color: '#14D4B2', linesColor: '#14D4B2' },
      servicesCards: [
        {
          icon: iconASR,
          title: this.$t("button['automatic speech recognition']"),
          languages: [this.$consts.lang.EN, this.$consts.lang.AR],
          introduction: this.$t('services.asa.ASR'),
          link: 'automatic-speech-recognition',
        },
        {
          icon: iconTTS,
          title: this.$t("button['text to speech']"),
          languages: [this.$consts.lang.EN, { code: this.$consts.lang.AR }],
          introduction: this.$t('services.asa.TTS'),
          link: 'text-to-speech',
        },
        {
          icon: iconSNSD,
          title: this.$t("button['speech non speech detection']"),
          introduction: this.$t('services.asa.SNSD'),
          link: 'speech-none-speech-detect',
        },
        {
          icon: iconSLD,
          title: this.$t("button['spoken language detection']"),
          introduction: this.$t('services.asa.SLD'),
          link: 'spoken-language-detection',
        },
      ],
    };
  },
};
</script>
